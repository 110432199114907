const {
  OfferTypes,
  ORDER_DISPLAY_STATUS,
  orderAction,
  packed,
  shipped,
  delivered,
  cencelled,
  pickedUP,
  StatusColor,
  cashDelivered,
  cashPickedUP,
  BUSINESS_TYPE,
  outForDelivery,
} = require('../constants/variables');

exports.validateResponse = (response) => {
  if (response.status === 204) {
    return;
  }
  if (!response.ok) {
    throw response;
  }
  return response.json();
};

exports.ImageResizeUrl = (url, width, height) => {
  let updatedUrl = '';
  if (url.includes('w_100,h_100,c_fill')) {
    updatedUrl = url.replace(
      'w_100,h_100,c_fill',
      `w_${width},h_${height},c_fill`
    );
  } else {
    updatedUrl = url.replace(
      '/upload/',
      `/upload/w_${width},h_${height},c_fill/`
    );
  }
  return updatedUrl;
};

exports.validAlphanumericWithHyphenString = (value) => {
  if (!value) {
    return true;
  } else {
    var regex = /^[a-zA-Z\d-_]+$/;
    if (value.match(regex)) {
      return true;
    } else {
      return false;
    }
  }
};

exports.validAlphanumericString = (value) => {
  if (!value) {
    return true;
  } else {
    var regex = /^[0-9a-zA-Z]+$/;
    if (value.match(regex)) {
      return true;
    } else {
      return false;
    }
  }
};
exports.offersMessage = (
  discount_type,
  max_discount,
  min_order_value,
  code,
  discount_value,
  free_unit,
  item_name,
  custom
) => {
  if (discount_type && min_order_value && code && !custom) {
    let description = '';
    switch (discount_type) {
      case OfferTypes.FLAT:
        if (discount_value) {
          description = `Use code ${code} and get ₹${discount_value} off on all the orders above ₹${min_order_value} `;
        }
        break;
      case OfferTypes.PERCENT:
        if (max_discount && discount_value) {
          description = `Use code ${code} and get ${discount_value}% off on all orders above ₹${min_order_value} upto ₹${max_discount} `;
        }
        break;
      case OfferTypes.FREEDELIVERY:
        if (max_discount) {
          description = `Use code ${code} and get free delivery on all the orders above ₹${min_order_value} upto ₹${max_discount} `;
        }
        break;
      case OfferTypes.FREEPRODUCT:
        if (min_order_value) {
          description = `Use code ${code} and get ${free_unit} ${item_name} for FREE on all the orders above ₹${min_order_value}`;
        }
        break;
      default:
        break;
    }
    return description;
  }
};

exports.getMinDate = () => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  today = yyyy + '-' + mm + '-' + dd;
  return today;
};

exports.getFormattedDate = (value) => {
  var today = new Date(value);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  today = yyyy + '-' + mm + '-' + dd;
  return today;
};

exports.specialCharactersRestriction = (value) => {
  if (
    value.includes('*') ||
    value.includes('[') ||
    value.includes(']') ||
    value.includes('+') ||
    value.includes('?') ||
    value.includes('(') ||
    value.includes(')') ||
    value.includes('<') ||
    value.includes('>') ||
    value.includes('\\') ||
    value.includes('{') ||
    value.includes('}') ||
    value.includes('.') ||
    value.includes(',') ||
    value.includes('/') ||
    value.includes(' ') ||
    value.includes('~') ||
    value.includes('!') ||
    value.includes('@') ||
    value.includes('#') ||
    value.includes('$') ||
    value.includes('%') ||
    value.includes('^') ||
    value.includes('&') ||
    value.includes(':') ||
    value.includes(';') ||
    value.includes('`') ||
    value.includes("'") ||
    value.includes('"') ||
    value.includes('|')
  ) {
    return true;
  }
};

exports.charactersRestriction = (value) => {
  const regex = /[A-Za-z]/g;
  if (value.match(regex)) {
    return true;
  } else {
    return false;
  }
};

exports.emailRestriction = (value) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
};

exports.phoneValidation = (value) => {
  var phoneNum = value.replace(/[^\d]/g, '');
  if (phoneNum.length > 6 && phoneNum.length < 11) {
    return true;
  }
};

exports.integersRestriction = (value) => {
  if (
    value.includes('*') ||
    value[0] === ' ' ||
    value.includes('<') ||
    value.includes('>') ||
    value.includes("'") ||
    value.includes('[') ||
    value.includes(']') ||
    value.includes('+') ||
    value.includes('?') ||
    value.includes('(') ||
    value.includes(')') ||
    value.includes('\\') ||
    value.includes('`') ||
    value.includes('{') ||
    value.includes('}') ||
    value.includes('.') ||
    value.includes(',') ||
    value.includes('/') ||
    value.includes('~') ||
    value.includes('!') ||
    value.includes('@') ||
    value.includes('#') ||
    value.includes('$') ||
    value.includes('%') ||
    value.includes('^') ||
    value.includes('&') ||
    value.includes(':') ||
    value.includes(';') ||
    value.includes('"') ||
    value.includes('|') ||
    value.includes('0') ||
    value.includes('1') ||
    value.includes('2') ||
    value.includes('3') ||
    value.includes('4') ||
    value.includes('5') ||
    value.includes('6') ||
    value.includes('7') ||
    value.includes('8') ||
    value.includes('9') ||
    value.includes('-') ||
    value.includes('=') ||
    value.includes('_')
  ) {
    return true;
  }
};

exports.getDDMMYYYY = (value) => {
  var today = new Date(value);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  today = dd + '-' + mm + '-' + yyyy;
  return today;
};

exports.getDDMMYYYYV2 = (value) => {
  var today = new Date(value);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  today = dd + '/' + mm + '/' + yyyy;
  return today;
};

exports.getDDMMYYYYV3 = (value) => {
  var today = new Date(value);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm === 1) {
    mm = 'Jan';
  } else if (mm === 2) {
    mm = 'Feb';
  } else if (mm === 3) {
    mm = 'Mar';
  } else if (mm === 4) {
    mm = 'Apr';
  } else if (mm === 5) {
    mm = 'May';
  } else if (mm === 6) {
    mm = 'Jun';
  } else if (mm === 7) {
    mm = 'Jul';
  } else if (mm === 8) {
    mm = 'Aug';
  } else if (mm === 9) {
    mm = 'Sep';
  } else if (mm === 10) {
    mm = 'Oct';
  } else if (mm === 11) {
    mm = 'Nov';
  } else {
    mm = 'Dec';
  }
  today = mm + ' ' + dd;
  return today;
};

exports.convHHMMtoAMPM = (value) => {
  var hrs = value.split(':')[0];
  var mins = value.split(':')[1];
  var median = 'AM';
  if (hrs >= 12) {
    median = 'PM';
  }
  if (hrs > 12) {
    hrs -= 12;
  }
  var AmPm = hrs + ':' + mins + ' ' + median;
  return AmPm;
};

exports.convertEpochTODateTime = (epoch) => {
  let currentTime;
  if (epoch) {
    currentTime = new Date(epoch);
  } else {
    currentTime = new Date();
  }
  let currentOffset = currentTime.getTimezoneOffset();
  let ISTOffset = 330;
  let ISTTime = new Date(
    currentTime.getTime() + (ISTOffset + currentOffset) * 60000
  )
    .toLocaleString()
    .replace(/\//g, '-');
  return ISTTime;
};

exports.isMobileOrTablet = () => {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
};

exports.isBroswerSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

exports.getFutureActionOptions = (
  status,
  orderType,
  paymentMethod,
  business_type
) => {
  let result = [];
  if (status && status === ORDER_DISPLAY_STATUS.pending) {
    result = orderAction;
  } else if (status && status === ORDER_DISPLAY_STATUS.accepted) {
    if (orderType === 'Delivery') {
      if (business_type === BUSINESS_TYPE.ECOM) {
        if (paymentMethod === 'online') {
          result = [packed, shipped, delivered, cencelled];
        } else {
          result = [packed, shipped, cashDelivered, cencelled];
        }
      } else {
        if (paymentMethod === 'online') {
          result = [packed, outForDelivery, delivered, cencelled];
        } else {
          result = [packed, outForDelivery, cashDelivered, cencelled];
        }
      }
    } else if (orderType === 'Takeaway') {
      if (paymentMethod === 'online') {
        result = [packed, pickedUP, cencelled];
      } else {
        result = [packed, cashPickedUP, cencelled];
      }
    }
  } else if (status && status === ORDER_DISPLAY_STATUS.packed) {
    if (orderType === 'Delivery') {
      if (business_type === BUSINESS_TYPE.ECOM) {
        if (paymentMethod === 'online') {
          result = [shipped, delivered];
        } else {
          result = [shipped, cashDelivered];
        }
      } else {
        if (paymentMethod === 'online') {
          result = [outForDelivery, delivered];
        } else {
          result = [outForDelivery, cashDelivered];
        }
      }
    } else if (orderType === 'Takeaway') {
      if (paymentMethod === 'online') {
        result = [pickedUP];
      } else {
        result = [cashPickedUP];
      }
    }
  } else if (
    status === ORDER_DISPLAY_STATUS.shipped ||
    status === ORDER_DISPLAY_STATUS.outForDelivery
  ) {
    if (orderType === 'Delivery') {
      if (paymentMethod === 'online') {
        result = [delivered];
      } else {
        result = [cashDelivered];
      }
    }
  }
  return result;
};

exports.showOrderUpdateButton = (status) => {
  let result = false;
  if (
    status &&
    (status === ORDER_DISPLAY_STATUS.cancelled ||
      status === ORDER_DISPLAY_STATUS.delivered ||
      status === ORDER_DISPLAY_STATUS.pickedUP)
  ) {
    result = true;
  }
  return result;
};

exports.showQuickOrderUpdateButton = (status) => {
  let result = false;
  if (
    status &&
    (status === ORDER_DISPLAY_STATUS.cancelled ||
      status === ORDER_DISPLAY_STATUS.delivered ||
      status === ORDER_DISPLAY_STATUS.pickedUP ||
      status === ORDER_DISPLAY_STATUS.pending)
  ) {
    result = true;
  }
  return result;
};

exports.getStatusColor = (status) => {
  let result;
  if (status && status === ORDER_DISPLAY_STATUS.pending) {
    result = StatusColor.PENDING;
  } else if (status === ORDER_DISPLAY_STATUS.accepted) {
    result = StatusColor.ACCEPTED;
  } else if (status === ORDER_DISPLAY_STATUS.packed) {
    result = StatusColor.PACKED;
  } else if (status === ORDER_DISPLAY_STATUS.cancelled) {
    result = StatusColor.CANCELLED;
  } else if (
    status == ORDER_DISPLAY_STATUS.delivered ||
    status == ORDER_DISPLAY_STATUS.pickedUP ||
    status == ORDER_DISPLAY_STATUS.shipped ||
    status == ORDER_DISPLAY_STATUS.outForDelivery
  ) {
    result = StatusColor.SUCCESS;
  }
  return result;
};

exports.showPaymentStatus = (method, status) => {
  let result;
  if (method && method === 'online') {
    result = {
      value: 'Paid',
      color: '#1DC417',
    };
  } else if (method === 'cash') {
    if (
      status === ORDER_DISPLAY_STATUS.delivered ||
      status === ORDER_DISPLAY_STATUS.pickedUP
    ) {
      result = {
        value: 'Paid',
        color: '#1DC417',
      };
    } else {
      result = {
        value: 'Not Paid',
        color: '#f33314',
      };
    }
  }
  return result;
};

exports.getProgressBarStatus = (status) => {
  let result = [];

  if (status === ORDER_DISPLAY_STATUS.delivered) {
    result = ['Accepted', 'Packed', 'Shipped', 'Delivered'];
  } else if (status === ORDER_DISPLAY_STATUS.shipped) {
    result = ['Accepted', 'Packed', 'Shipped'];
  } else if (status === ORDER_DISPLAY_STATUS.pickedUP) {
    result = ['Accepted', 'Packed', 'Picked Up'];
  } else if (status === ORDER_DISPLAY_STATUS.packed) {
    result = ['Accepted', 'Packed'];
  } else if (status === ORDER_DISPLAY_STATUS.accepted) {
    result = ['Accepted'];
  } else if (status == ORDER_DISPLAY_STATUS.outForDelivery) {
    result = ['Accepted', 'Packed', 'Out For Delivery'];
  }
  return result;
};

import Firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import * as firebaseui from 'firebaseui';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};
const firebase = Firebase.initializeApp(firebaseConfig);

const fanalytics = Firebase.analytics();
const fstore = Firebase.firestore();
const frtdb = Firebase.database();
const fauth = Firebase.auth();
const fLoginUi = new firebaseui.auth.AuthUI(Firebase.auth());

export const fgetUser = async () => {
  let token;
  try {
    if (fauth?.currentUser) token = await fauth.currentUser.getIdToken();
  } catch {
    console.log('Error occured while logging out user');
  }
  return token;
};

export const fBaseLogOut = async () => {
  let isSuccess = false;
  try {
    window.sessionStorage.clear();
    await fauth.signOut();
    isSuccess = true;
  } catch {
    console.log('Error occured while logging out user');
  }
  return isSuccess;
};

export const getOrdersToday = async (location) => {
  var dayStart = new Date();
  dayStart.setHours(0, 0, 0, 0);

  return await getUserOrdersBetweenTimestamp(location, dayStart.getTime());
};

export const getUserOrdersBetweenTimestamp = async (
  location,
  startTimeStamp,
  endTimeStamp
) => {
  let snapshot = fstore
    .collection('locations')
    .doc(location)
    .collection('orders')
    .where('created', '>=', startTimeStamp);
  if (endTimeStamp) {
    snapshot = snapshot.where('created', '<=', endTimeStamp);
  }
  snapshot = await snapshot.orderBy('created', 'desc').get();
  return snapshot.docs.map((doc) => {
    let order = doc.data();
    order.order_no = doc.id;
    return order;
  });
};

export const updateOrderStatusDashboard = async (order_no, status, reason) => {
  const ref = fstore
    .collection('locations')
    .doc(order_no.split('-')[0])
    .collection('orders')
    .doc(order_no);
  const updateBody = {};
  updateBody[`meta.current_status`] = status;
  updateBody[`meta.status_reason`] = reason ? reason : '';
  await ref.update(updateBody);
  return true;
};

export function getStoreLogo(menuId) {
  return new Promise((resolve, reject) => {
    fstore
      .collection('menu')
      .doc(menuId)
      .get()
      .then((snapshot) => {
        let result = snapshot.data();
        if (result) {
          resolve(result.meta_data.logo);
        } else {
          reject(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getBussinessType(menuId) {
  return new Promise((resolve, reject) => {
    fstore
      .collection('menu')
      .doc(menuId)
      .get()
      .then((snapshot) => {
        let result = snapshot.data();
        if (result) {
          resolve(result.meta_data.business_type);
        } else {
          reject(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export { frtdb, firebase, fanalytics, fstore, fauth, fLoginUi };
